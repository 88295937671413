<template>
  <div>
    <v-row>
      <v-col>
        <h1 class="text-center">
          Selecciona día y hora
        </h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-date-picker
            color="primary"
            event-color="primary"
            no-title
            v-model="fecha"
            :min="today"
            full-width
            first-day-of-week="1"
            locale="es"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-list v-if="!is_loading && (!horas || !horas.length)">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>No hay horas disponibles para este día</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>

    <v-row class="contHoras">
      <v-col>
        <v-list v-if="horas && horas.length">
          <v-list-item v-for="(hora, index) in horas" :key="index" @click="set_hora(hora)">
            <v-list-item-content>
              <v-list-item-title v-text="hora.hora"></v-list-item-title>
            </v-list-item-content>
            <v-list-item-avatar>
              <v-icon>mdi-arrow-right</v-icon>
            </v-list-item-avatar>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </div>
</template>


<script>
import show_toolbar_mixin from "@/mixins/show_toolbar_mixin";
import {VueEasyJwt} from 'vue-easy-jwt'
import send_stats_mixin from "../mixins/send_stats_mixin";
import get_utms from '../utils/utm_params'

export default {
  name: 'Calendar',
  mixins: [show_toolbar_mixin, send_stats_mixin],

  data() {
    return {
      jwt: new VueEasyJwt(),
      today: this.$moment().format("YYYY-MM-DD"),
      fecha: this.$moment().format("YYYY-MM-DD"),
      horas: [],
      utms: get_utms()
    }
  },

  methods: {
    async get_horas() {
      this.horas = await this.$store.dispatch('reserva/get_horas', {
        fecha: this.fecha,
        centro: this.$store.state.reserva.centro,
        servicio: {
          id: this.$store.state.reserva.servicio
        },
        include_genitales_masculinos: this.$store.state.reserva.include_genitales_masculinos
      })
    },

    set_hora(hora) {
      this.$store.commit('reserva/set_hora', hora.hora)
      this.$store.commit('reserva/set_empleado', hora.empleado)

      if (this.$store.state.auth.user && !this.jwt.isExpired(this.$store.state.auth.token)) {
        this.$router.push({ name: 'confirmar_reserva', query: this.utms })
      } else {
        this.$router.push({
          name: 'login', query: {
            next: 'confirmar_reserva',
            utm_source: this.utms.utm_source,
            utm_medium: this.utms.utm_medium,
            utm_campaign: this.utms.utm_campaign
          }
        })
      }
    }
  },

  mounted() {
    this.$store.commit('reserva/set_fecha', this.fecha)
    this.get_horas()
  },

  computed: {
    centro() {
      return this.$store.state.auth.user
    },
    is_loading() {
      return this.$store.state.loading
    }
  },

  watch: {
    fecha(val) {
      this.horas = [];
      this.$store.commit('reserva/set_fecha', val)
      this.get_horas()
    }
  }
}
</script>

<style lang="less" scoped>
.contHoras {
  height: calc(100vh - 400px);
  overflow-y: auto;
}
</style>
